<template>
  <div id="home-super-container">
    <v-runtime-template :template="homePageTemplate"></v-runtime-template>
  </div>
</template>

<script>
import VRuntimeTemplate from 'vue3-runtime-template';

export default {
  name: 'Home',
  components: { VRuntimeTemplate },
  inheritAttrs: false,
  props: {
    instanceInfo: {
      type: Object,
      required: false,
    },
    subscriptionPlan: {
      type: Object,
      required: true,
    },
    homePageTemplate: {
      type: String,
      required: false,
    },
  },
};
</script>
